@import "../../../../assets/css/base.scss";
.profile_pic {
  position: $relative;
  display: $inline-block;
  width: auto;
  figure {
    padding: 0;
    border: 0;
    width: 140px;
    height: 140px;
    span {
      width: 140px;
      height: 140px;
      img {
        width: 140px;
        height: 140px;
        object-fit: cover;
      }
    }
  }
  label[for="files"] {
    position: $absolute;
    bottom: 10px;
    margin: 0;
    background: $primary-color;
    border: 2px solid $white-color;
    padding: 8px;
    border-radius: 20px;
    width: 44px;
    height: 44px;
    overflow: hidden;
    @include flexbox;
    align-items: center;
    justify-content: center;
    right: 0px;
    img {
      width: 100%;
    }
  }
}
