// _fonts.scss
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

$default-font:'Raleway';

$f-extra: 900;
$f-extra: 800;
$f-bold: 700;
$f-semi-bold:600;
$f-medium: 500;
$f-regular: 400;
$f-light: 300;
$f-extra-light: 100;

$f12:12px;
$f16:16px;
$f20:20px;
$f30:30px;
$f40:40px;

$left: left;
$center: center;
$right: right;