// _variable.scss

$none: none;
$block: block;
$inline-block: inline-block;

$relative: relative;
$absolute: absolute;
$static: static;
$fixed: fixed;

$center: center;
$left: left;

$capitalize: capitalize;
$uppercase: uppercase;
$lowercase: uppercase;

$pointer: pointer;