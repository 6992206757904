@import '../css/base.scss';
/*
==========================================================
Reset CSS
==========================================================
*/
html, body {height: 100%;}
h1, h2, h3, h4, h5, h6 {font-weight:$f-bold; line-height: normal; color:$black-color;}
ul {margin:0; padding:0;}
h1 {
  font-size:$f40+4;
  @media (max-width:767px){
    font-size:$f30+8;
  }
}
h2 {
  font-size:$f30+6;
  @media (max-width:767px){
    font-size:$f30+2;
  }
}
h3 {
  font-size:$f20+8;
  @media (max-width:767px){
    font-size:$f20+4;
  }
}
h4 {
  font-size:$f20+3;
  @media (max-width:767px){
    font-size:$f20;
  }
}
h5 {
  font-size:$f20+1;
  @media (max-width:767px){
    font-size:$f16+3;
  }
}
h6 {
  font-size:$f16+3;
}
p {font-size:$f16; color: $grey-dark-color;}
a {color: $grey-dark-color; transition:all .5s ease-in-out; -webkit-transition:all .5s ease-in-out;
  &:hover, &:focus {
    text-decoration:$none;
  }
}
label {font-size:$f16; line-height:$f16+2; font-weight:$f-semi-bold; margin-bottom:.5rem;}

#root{@include flexbox; @include align-items (flex-start); height: 100%;}
.form-group {margin-bottom:15px;}
.form-control{
  min-height:46px; 
  @include border-radius (7px); 
  border:1px solid $grey-color4; 
  background-color:$grey-color3; 
  color: $grey-dark-color;
  &:focus {
    box-shadow:$none; 
    border-color: $primary-color;
  }
}
.form-select{	
  box-shadow:$none; outline: $none;
  border-color: $transparent;
}
select {
  appearance: $none; 
  -webkit-appearance: $none; 
  // background-image:url(../../src/assets/themes/images/select_arw.png); 
  // background-repeat:no-repeat; 
  // background-size:17px; 
  // background-position:96% center;
}
button{
  &:hover, &:focus {
    box-shadow: $none !important; 
    outline: $none !important; 
  }
}
.btn {
  padding:.475rem 1rem .4rem; 
  text-align:$center; 
  font-weight:$f-medium;
  &:focus, &:focus{
    box-shadow: $none;
  }
  @media (max-width:1200px){
    font-size: $f12+2;
  }
  @media (max-width:575px){
    font-size: $f12+1;
  }
}
.btn-lg {
  min-height:50px; 
  min-width:200px;
  @include border-radius (7px); 
  font-size:$f16+2; 
  @media (max-width:1200px) {
    min-width:150px;
    min-height:44px; 
    font-size:$f16;
  }
  @media (max-width:991px) {
    font-size:$f12+2; 
  }
}
.btn-md {
  min-width:140px; 
  font-size:$f16;
  min-height:44px;
  padding:.58rem 1.5rem; 
  @include border-radius (5px);
  @media (max-width:991px){
    min-width: auto;
    font-size:$f12+2;
  }
  @media (max-width:575px){
    min-height:40px;
  }
}
.btn-sm {
  min-width:70px; 
  font-size:$f12+2; 
  padding:.55rem 1.5rem; 
  @include border-radius (5px);  
  @media (max-width:991px){
    font-size:$f12+1;
    padding:.45rem 1.2rem; 
  }
}
.btn-primary {
  background-color:$primary-color;
  border-color:$primary-color; 
  cursor:$pointer;
  &:hover, &:focus {
    background-color:$dark-green-color!important; 
    border-color:$dark-green-color!important; 
    color:$white-color;
  } 
}
.btn-secondary {
  color:#76797c;
  &:hover, &:focus { 
    background-color: #e1e8ef; 
    border-color: #76797c;   
  }
}
.btn-white {
  background:$white-color; 
  border-color:$white-color; 
  color:$black-color1; 
  &:hover, &:focus {
    background-color:$white-color;
    border-color:$white-color;
  }   
}
.btn:not(:disabled):not(.disabled).active:focus, .btn:not(:disabled):not(.disabled):active:focus, .show>.btn.dropdown-toggle:focus{box-shadow: $none;}

/** Input Css**/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: $none;
  margin: 0;
}
input[type=number] { 
  -moz-appearance:textfield; 
} 
label input[type="radio"] {
	@include opacity (0);
}

sub, sup {font-size:$f16; top:-2px;}
/** Placeholder Input **/
::placeholder { 
/* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $base-color;
  @include opacity (1);
}
:-ms-input-placeholder { 
/* Internet Explorer 10-11 */
  color: $base-color;
}
::-ms-input-placeholder {
	/* Microsoft Edge */
  color: $base-color;
}
textarea {min-height:120px !important;}
hr {border-color:$base-color;}
/* input:-internal-autofill-selected {color: -internal-light-dark(#4d4d4d, white) !important;} */
/**Custom Radio css**/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: $absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  display:$inline-block;
  position: $relative;
  padding-left: 28px;
  line-height: 11px;
  cursor: $pointer;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: $absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background: #ddd;
    @include border-radius (100%);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    position: $absolute;
    top: 0px;
    left: 0px;
    @include border-radius (100%);
    background: $primary-color;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    @include opacity (0);
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    @include opacity (1);
    -webkit-transform: scale(1);
    transform: scale(1);
}
[type="radio"] + span:before {
  content: '';
  width: 12px;
  height: 12px;
  margin-right: 0.75em;
  display: $inline-block;
  @include border-radius (1em);
  border: 1.5px solid $white-color;
  box-shadow: 0 0 0 2px $black-color;   
  -webkit-transition: 0.5s ease all;   
  transition: 0.5s ease all;   
} 
/* the checked style using the :checked pseudo class */
[type="radio"]:checked + span:before {
  background: $black-color;
  box-shadow: 0 0 0 2px $black-color;
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: $absolute; 
  width:100%;
  height:100%;
  @include opacity (0);
  z-index:1;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: $relative;
  padding-left: 1.95em;
  cursor: pointer;
  margin-bottom: 15px;
}
[type="checkbox"]:checked + label:before {
  background: $primary-color;
	
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: $absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 2px solid $primary-color;
  @include border-radius (4px);
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
.custom_checkbox {position:relative;}
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: '\2713\0020';
    position: $absolute;
    top: 0.33em;
    left: .22em;
    font-size: 1em;
    line-height: 0.8;
    color: $white-color;
    transition: all .2s;
    -webkit-transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  @include opacity (0);
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  @include opacity (1);
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: $none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
   border: 2px solid $primary-color!important;
}

.table-fixed {table-layout: $fixed;}