// _base.scss
@import 'variable';
@import 'flexbox';
@import 'mixin';
@import 'fonts';

$primary-color:#0D532D;
$secondary-color:#cbeaf7;
$base-color:#4D4D4F;
$grey-color:#ECEDF1;
$grey-color1:#E6E6E6;
$grey-color2:#8D8D8E;
$grey-color3:#F7F7F7;
$grey-color4:#B7B7B7;
$grey-color5:#D9D9D9;
$grey-color6:#E2E2E2;
$grey-color7:#C1C1C1;
$grey-color8:#ACACAC;
$grey-color9:#928e8e;
$grey-color10:#E8E8E8;
$grey-dark-color: #4A4A4A;
$grey-dark-color1: #3B3B3B;
$white-color:#ffffff;
$black-color:#000000;
$black-color1:#343434;
$black-color2:#707070;
$black-color3:#2E2E2E;
$black-light-color:#a3a3a3;
$dark-green-color: #033a1c;
$red-color: #ff0000;
$transparent: transparent;
