@import '../../../../assets/css/base.scss';

.breadcrumbs {
  @include flex-wrap (wrap);
  @media (max-width:767px) {
    margin-top:10px;
  }
  h4 {
    color:$primary-color;
    font-size: 1.4rem;
    line-height: 1.5;
    @media (max-width:767px){
       font-size: 1.2rem;
    }
  }
  span {
    color: $primary-color;
    font-size: 1.4rem;
    @include flexbox;
    @media (max-width:767px){
       font-size: 1.2rem;
    }
    i {
      margin: -2px 16px 0;
      img {
          width:12px;
          @media (max-width:767px){
            width:9px;
          }
      }
    }
  }
}

.card_tab {
  @media (min-width:576px){
    border-bottom: 1px solid $primary-color;
  }
  li {
    margin: 0;
    min-width:160px;
    min-height: 44px;
    cursor: $pointer;
    line-height:$f16;
    text-align:$center;    
    padding:.95rem 2rem;
    color: $primary-color;
    display: $inline-block;
    @include border-radius (8px 8px 0 0);
    @media (max-width:575px){
      border:1px solid $primary-color;
      @include border-radius (0);
      padding:.85rem 1rem;
      @include flexbox;
      @include align-items (center);
      @include justify-content (center);
      min-width: 50%;
      max-width: 50%;
      // border-bottom:0;
      // &:last-child {
      //   min-width: 100%;
      // }
    }
    &.active {
      background: $primary-color;
      color: $white-color;            
    } 
  }
}

.history {
  ul li {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}
