@import "assets/css/base.scss";

body {
  font-family: $default-font !important;
  background: $grey-color;
  color: $grey-dark-color;
  margin: 0;
  font-size: $f16;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: $transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
  }
}
#diagramcontent {
  &::-webkit-scrollbar {
    height: 6px;
  }
  scrollbar-color: #eb8106 rgba(0, 0, 0, 0) !important;
  scrollbar-height: thin !important;
}
#content-wrapper {
  @include transition(all, 0.3s, ease-out);
  background-color: $grey-color;
  height: 100%;
  width: 100%;
  @media (min-width: 992px) {
    padding-left: 280px;
  }
}
@media (max-width: 830px) {
  div.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  div.card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.page_box {
  box-shadow: 0px 3px 14.72px 1.28px rgba(0, 0, 0, 0.1);
  @include border-radius(20px 20px 0 0);
  min-height: calc(100vh - 90px);
  background: $white-color;
  position: $relative;
  margin-top: 90px;
  z-index: 1;
  @media (max-width: 767px) {
    margin-top: 70px;
    min-height: calc(100vh - 70px);
  }
}
div.card {
  @include border-radius(20px);
  border-width: 0;
  @media (max-width: 767px) {
    @include border-radius(100px);
  }
  .card-header {
    @include border-radius(20px 20px 0 0);
    background: $white-color;
    border: 0;
    @media (min-width: 992px) {
      padding: 20px 30px;
    }
    h4 {
      color: $primary-color;
    }
  }
  .card-body {
    @media (min-width: 992px) {
      padding: 30px;
    }
  }
  .card-footer {
    background-color: $white-color;
    border: 0;
    padding: 20px 30px;
  }
}
.shadow {
  box-shadow: 0px 3px 14.72px 1.28px rgba(0, 0, 0, 0.1);
}
.no-data-found {
  width: 600px;
  margin: 0 auto;
  min-height: 300px;
  font-weight: $f-medium;
  @media (max-width: 991px) {
    width: 580px;
    min-height: 280px;
  }
  i {
    img {
      width: 22px;
    }
  }
  p {
    //color:#e25057;
    &:last-child {
      margin: 0;
    }
  }
}

.disabled,
.disable {
  pointer-events: $none;
  background: $grey-color8 !important;
  border: 0 !important;
}

.btn-cancel {
  background: $grey-color5 !important;
  border: 1px solid $grey-color7 !important;
}
.btn-contain {
  .btn {
    margin: 4px;
  }
}
